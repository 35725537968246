<template>
   <div class="modal"  >
        <div class="modal-down-body">
            <img src="~@/assets/img/modal-title.png" class="moadl-title" alt="">
            <img src="~@/assets/img/modal-del.png"  class="moadl-del" alt="" @click="close()">
            <img src="@/assets/img/modal-logo.png"  class="moadl-logo" alt="">
            <div class="urlTitle">
                <div>360ba.live</div>  
                <div>永久观看网址</div>
            </div>
            <img src="~@/assets/img/modal-cope.png"  class="moadl-cope" @click="copyUrl()" alt="">
            <div id="qrcode-image" ref="qrcode-image" class="qrcode" />
        </div>
   </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { mapState } from 'vuex';
export default {
    name: 'wsDownloadPopup',
    data() {
        return {
          
        }
    },
    async mounted() {
      await this.$store.dispatch('getDownloadUrls');
      this.makeQrcode();
    },
    computed: mapState(['iosUrl', 'androidUrl', 'androidUrlBak','downloadPageUrl']),
    methods: {
      copyUrl() {
        navigator.clipboard.writeText('360ba.live')
          .then(() => {
            this.$message.success('已复制域名');
            console.log('Copied!');
          })
          .catch(err => {
            console.error('Failed to copy!', err);
          });
      },
      makeQrcode() {
        new QRCode("qrcode-image", {
          width: 99, //宽度
          height: 99, // 高度
          text: this.downloadPageUrl || location.href
        });
      },
      close(){
        this.$emit('close',false);
      }
    },
}
</script>
<style lang="less">
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.7);

    .modal-down-body {
      position: fixed;
      top: 45%;
      left: 50%;
      z-index: 999;
      transform: translate(-50%, -50%);
      color: #ffffff;
      border-radius: 16px;
      width: 489px;
      height: 341px;
      box-sizing: border-box;
      background: url("~@/assets/img/modal-bg.png");
      background-size: 100% 100%;
      .moadl-title{
        width: 306px;
        height: 94px;
        position: absolute;
        left: 21px;
        top: 94px;
        cursor: pointer;
      }
      .moadl-del {
        width: 35px;
        height: 35px;
        position: absolute;
        top: -20px;
        right: -20px;
        cursor: pointer;
      }
      .moadl-logo{
        width: 52px;
        height: 52px;
        position: absolute;
        bottom: 55px;
        left: 19px;
      }
      .urlTitle{
            position: absolute;
            bottom: 55px;
            left: 88px;
            font-family: YouSheBiaoTiHei;
            font-size: 18px;
            color: #462B22;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            font-weight: bold;
      }
      .moadl-cope{
        width: 20px;
        height: 21px;
        position: absolute;
        bottom: 90px;
        left: 202px;
      }
      .qrcode{
        position: absolute;
        bottom: 32px;
        right: 132px;
      }
    }
  }

</style>